export function convertMinutesToTimeFormat(minutes: number) {
    const days = Math.floor(minutes / (24 * 60));
    minutes %= (24 * 60);
    const hours = Math.floor(minutes / 60);
    minutes %= 60;

    const daysStr = days > 0 ? `${days}d ` : '';
    const hoursStr = hours > 0 ? `${hours}h ` : '';
    const minutesStr = minutes > 0 ? `${minutes} mins` : '';

    return `${daysStr}${hoursStr}${minutesStr}`.trim();
}